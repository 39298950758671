require("normalize.css/normalize.css");
require("../scss/index.scss");

// ······································································
// IMPORTS
// ······································································

// SWIPER
import Swiper, { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
Swiper.use([Navigation]);

// SELECT2
import "select2";
import "select2/dist/css/select2.css";

// aos
import AOS from "aos";
import "aos/dist/aos.css";

// animate
import "animate.css";

var lazyImg = require("./lazy-img");
var lazyBgImg = require("./lazy-bg-img");

// PRINT.JS
// import print from 'print-js'

// jsPDF + html2pdf
// import html2pdf from 'html2pdf.js';
// import jsPDF from 'jspdf';

// ······································································
// COMMONS
// ······································································

// Language Setting

let locale = $("html").attr("lang");

// Buttons Interaction

$(".btn").each(() => {
  $(this).css("transition", "all 0.4s ease");
});

// Swipers

var swiper_viatges = new Swiper("#viatges-swiper", {
  slidesPerView: "auto",
  spaceBetween: 0,
  navigation: {
    nextEl: ".carousel__nav__right",
    prevEl: ".carousel__nav__left",
  },
  breakpoints: {
    991: {
      spaceBetween: 20,
    },
    1201: {
      slidesPerView: 3,
      spaceBetween: 25,
    },
  },
});

var swiper_destinacions = new Swiper("#destinacions-swiper", {
  slidesPerView: "auto",
  spaceBetween: 0,
  autoHeight: true,
  navigation: {
    nextEl: ".carousel__nav__right",
    prevEl: ".carousel__nav__left",
  },
});

var swiper_equip = new Swiper("#equip-swiper", {
  slidesPerView: "auto",
  spaceBetween: 0,
  navigation: {
    nextEl: ".carousel__nav__right_equip",
    prevEl: ".carousel__nav__left_equip",
  },
  breakpoints: {
    677: {
      spaceBetween: 20,
    },
    991: {
      spaceBetween: 25,
    },
    1201: {
      slidesPerView: 3,
      spaceBetween: 25,
    },
  },
});

var swiper_comentaris = new Swiper("#comentaris-swiper", {
  slidesPerView: 1,
  autoHeight: true,
  loop: true,
  autoplay: true,
  navigation: {
    nextEl: ".swiper__nav__right",
    prevEl: ".swiper__nav__left",
  },
});

var swiper_viatges = new Swiper("#noticia-swiper", {
  slidesPerView: "auto",
  spaceBetween: 15,
  navigation: {
    nextEl: ".carousel__nav__right",
    prevEl: ".carousel__nav__left",
  },
  breakpoints: {
    991: {
      spaceBetween: 20,
    },
    1201: {
      spaceBetween: 25,
    },
  },
});

// var swiper_relacionats = new Swiper('#relacionats-swiper', {
//     slidesPerView: 3,
//     spaceBetween: 25,
//     navigation: {
//         nextEl: ".carousel__nav__right2",
//         prevEl: ".carousel__nav__left2",
//     },
// });

// var swiper__slidecount = swiper_viatges.slides.length;
// 		alert(swiper__slidecount);

// FORMS
// -----

function selectFormPrepare() {
  $(".js-select2").each(function () {
    let element = $(this);
    let placeholder = element.data("placeholder");

    element.select2({
      placeholder: placeholder,
      minimumResultsForSearch: 14,
      // dropdownAutoWidth: 'true',
    });
  });
}

function contactFormPrepare() {

  // set params in hidden field
  const params = getAllUrlParams();
  if (params.viatge) {
      document.getElementById('contact-form-viatge').value = params.viatge;
  }

}

function newsletterFormPrepare() {

  const form = document.getElementById('form-newsletter');
  const submit_btn = form.querySelector('input[type="submit');
  const positive = document.querySelector('.form-positive[data-form="form-newsletter"]');
  console.log(positive);

  // submit_btn.addEventListener('click',)
  form.addEventListener('submit', checkIfPositive);
  positive.addEventListener('click', markPositive);

  function checkIfPositive(event) {
    if (!positive.classList.contains('active')) {
      event.preventDefault();
      positive.classList.add('signal');
    }
  }

  function markPositive() {
    positive.classList.toggle('active');
    positive.classList.remove('signal');
  }

}


// ······································································
// UTILS
// ······································································

// ----------------------------------------------------------------------------
// SET SUPERPOSITION CLASS
//
// Target element will have a class when it is superposed to (trigger)
// element(s).
//
// Applications:
// - Header is fixed with black text/logo and becomes unvisible when it's
//  on top of a dark section.
//
// Instructions:
// - Create all your superpositions rules in updateSuperpositions()
// - Call it on window scroll and window load
//
// Future work:
// - Only works when window is the main scroller, make a version with a custom
//   scroller element
// ----------------------------------------------------------------------------

function setSuperpositionClass(target_selector, trigger_seletor, class_name) {
  let target = $(target_selector);

  var top = target.offset().top;
  var height = target.outerHeight();
  var bottom = target.offset().top + height;

  var orange = false;

  $(trigger_seletor).each(function () {
    var section = $(this);
    var top_of_section = section.offset().top;
    var bottom_of_section = section.offset().top + section.outerHeight();

    if (
      top + 0.5 * height >= top_of_section &&
      bottom - 0.5 * height < bottom_of_section
    ) {
      orange = true;
    }
  });

  if (orange) {
    target.addClass(class_name);
  } else {
    target.removeClass(class_name);
  }
}

function updateSuperpositions() {
  //--- call on load

  // floating arrow (footer)
  if ($("#floating-arrow")[0])
    setSuperpositionClass(
      "#floating-arrow",
      ".seccio--blue, #cta-single-viatge",
      "orange"
    );

  // cta single viatge
  if ($("#cta-single-viatge")[0])
    setSuperpositionClass("#cta-single-viatge", ".seccio--blue", "orange");
}

// ----------------------------------------------------------------------------
// GET ALL URL PARAMS
// source: https://www.codegrepper.com/profile/mysterious-mosquito-ntw1hk6d203w
//
// extract an array of parameters from the url
//
// note: if url not provided, it reads current url
//
// ----------------------------------------------------------------------------

function getAllUrlParams(url) {
  // get query string from url (optional) or window
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];

    // split our query string into its component parts
    var arr = queryString.split("&");

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split("=");

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === "undefined" ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === "string") paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, "");
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === "string") {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

// ----------------------------------------------------------------------------
// REMOVE A PARAMETER FROM THE URL
// source: https://veesworld.com/jquery/how-to-remove-parameters-in-url-using-javascript
// remove a parameter from current url
// ----------------------------------------------------------------------------

function removeParams(sParam) {
  var url = window.location.href.split("?")[0] + "?";
  var sPageURL = decodeURIComponent(window.location.search.substring(1)),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");
    if (sParameterName[0] != sParam) {
      url = url + sParameterName[0] + "=" + sParameterName[1] + "&";
    }
  }
  window.location = url.substring(0, url.length - 1);
}

// ------------------------------------------------------------
// RESPONSIVE
// - calculate basic screen/resonsive variables to use in your code
// ------------------------------------------------------------

var screenWidth, screenHeight;
var isLaptop, isTablet, isMobile, isMobileSmall;

function calcScreen() {
  screenWidth = window.innerWidth;
  screenHeight = window.innerHeight;

  if (screenWidth <= 1400) isLaptop = true;
  else isLaptop = false;
  if (screenWidth <= 990) isTablet = true;
  else isTablet = false;
  if (screenWidth <= 767) isMobile = true;
  else isMobile = false;
  if (screenWidth <= 430) isMobileSmall = true;
  else isMobileSmall = false;
}

// ······································································
// HEADER
// ······································································

// VIATGES DEPLOY

function prepareMenuDisplays(target) {

  const viatges_btn = document.getElementById("menu-viatges");
  const viatges_box = document.getElementById("menu-viatges-box");
  const viatges_box_tooltip = document.getElementById("menu-viatges-tooltip");

  const viatges_btn_mobile = document.getElementById("menu-viatges-mobile");
  const viatges_box_mobile = document.getElementById("menu-viatges-box-mobile");

  const temporada_btn = document.getElementById("menu-temporada");
  const temporada_box = document.getElementById("menu-temporada-box");
  const temporada_box_tooltip = document.getElementById(
    "menu-temporada-tooltip"
  );

  const temporada_btn_mobile = document.getElementById("menu-temporada-mobile");
  const temporada_box_mobile = document.getElementById(
    "menu-temporada-box-mobile"
  );

  // viatges desktop
  if (target == viatges_btn) {
    viatges_box.classList.toggle("hidden");
    viatges_box_tooltip.classList.toggle("hidden");
    $(viatges_box).children().children().toggleClass("hidden");
  } else if (target !== viatges_box) {
    viatges_box_tooltip.classList.add("hidden");
    viatges_box.classList.add("hidden");
    $(viatges_box).children().children().addClass("hidden");
  }

  // viatges mobile
  if (target == viatges_btn_mobile) {
    viatges_box_mobile.classList.toggle("hidden");
  } else if (target.classList.contains("accordion-trigger")) {
    // accordion mobile
    const display_id = target.getAttribute("data-display");
    document.getElementById(display_id).classList.toggle("hidden");
  } else if (target !== viatges_box_mobile) {
    viatges_box_mobile.classList.add("hidden");
  }

  // temporada desktop
  if (target == temporada_btn) {
    temporada_box.classList.toggle("hidden");
    temporada_box_tooltip.classList.toggle("hidden");
    $(temporada_box).children().children().toggleClass("hidden");
  } else if (target !== temporada_box) {
    temporada_box_tooltip.classList.add("hidden");
    temporada_box.classList.add("hidden");
    $(temporada_box).children().children().addClass("hidden");
  }

  // temporada mobile
  if (target == temporada_btn_mobile) {
    temporada_box_mobile.classList.toggle("hidden");
  } else if (target !== temporada_box_mobile) {
    temporada_box_mobile.classList.add("hidden");
  }
}

// ······································································
// LANDING
// ······································································

// INTERACTIONS DESTINACIONS

$(".js-menu-destinacions").on("mouseenter", function () {
  let id = $(this).data("id");
  $(id).css("right", "0");
});

$(".js-menu-destinacions").on("mouseleave", function () {
  let id = $(this).data("id");
  $(id).css("right", "-140%");
});

// ······································································
// SEARCH
// ······································································

// search selectors (remove conditions)
$(".js-deselect").on("click", function () {
  const el = $(this);

  // item that should be deselected
  const item = el.data("item");
  removeParams(item);
});

// ······································································
// SINGLE VIATGE
// ······································································

// CTA

let cta = $("#cta-single-viatge");
let cta_section = $("#s1");
let cta_section_next = $("#s4");
let floating_arrow = $("#floating-arrow");
let cta_h;
let cta_section_h;
let cta_section_next_h;

function calcCTA() {
  cta_h = cta.offset().top;
  cta_section_h = cta_section.offset().top;
  cta_section_next_h = cta_section_next.offset().top;
}

function updateCTA() {
  if ($(window).scrollTop() >= cta_h - cta.height() / 2 + 20) {
    // theorically 10 should be 30 but makes a weird jump...
    cta.addClass("fix");
  } else {
    cta.removeClass("fix");
  }

  console.log($(window).scrollTop());
  console.log(cta_section_h);
  console.log(cta_section_h);
  console.log(cta_section_next_h);

  if (
    $(window).scrollTop() >= cta_section_h - 0.5 * cta_section.height() + 20 &&
    $(window).scrollTop() < cta_section_next_h - cta_section_next.height() + 20
  ) {
    cta.addClass("show");
    floating_arrow.addClass("hide");
  } else {
    cta.removeClass("show");
    floating_arrow.removeClass("hide");
  }
}

// popup nivells de trek
$(".js-nivell-de-trek").on("click", function () {
  $("#pop-up-trekking").toggleClass("show");
});

function unstyleRichText(restyleWrapper) {
  for (let i = 0; i < restyleWrapper.length; ++i) {
    let elements = restyleWrapper[i].querySelectorAll(
      "table, th, td, ul, li, h1, h2, h3, h4, h5, h6, img"
    );

    for (let j = 0; j < elements.length; ++j) {
      elements[j].removeAttribute("style");
    }
  }
}

// ······································································
// menu HAMBURGER
// ······································································

function hamburgerEvents() {
  const hamburgers = $("#hamburger");
  const menupage = $("#menupage");
  const logomobile = $("#logomobile");

  hamburgers.on("click", () => {
    // hide menu
    if (menupage.hasClass("expanded")) {
      menupage.removeClass("expanded");
      hamburgers.removeClass("expanded");
      logomobile.removeClass("expanded");

      $("html, body").css("overflow", "initial");

      // display menu
    } else {
      menupage.addClass("expanded");
      hamburgers.addClass("expanded");
      logomobile.addClass("expanded");

      if (isTablet) {
        // we delay this in order to not see the page jump on click
        setTimeout(() => {
          $("html, body").css("overflow", "hidden");
        }, 600); // menumobile opacity transition duration
      }
    }
  });
}
hamburgerEvents();

// ······································································
// removeJustified
// ······································································

function removeJustified() {
  var paragraphs = Array.prototype.slice.call(
    document.getElementsByTagName("p")
  );

  paragraphs.forEach((p) => {
    if (p.style.textAlign == "justify") p.style.textAlign = "left";
  });
}

// ······································································
// WINDOW LISTENERS - LOAD
// ······································································

calcScreen();

$(document).ready(function () {
  window.addEventListener("load", AOS.refresh());
});

(function () {
  $(function () {
    calcScreen();

    // functions to be loaded BEFORE making page visible
    selectFormPrepare();
    if ($(".restyle")[0])
      unstyleRichText(document.querySelectorAll(".restyle"));

    removeJustified();

    // // aos
    AOS.init({
      once: true,
    });

    setTimeout(() => {
      // make page visible
      $("body").removeClass("hidden");

      // animation start
      // window.requestAnimationFrame(animateFunction);

      
      // functions to be loaded AFTER making page visible
      if ($("body.page-contacte")[0]) contactFormPrepare();
      if ($("#form-newsletter")[0]) newsletterFormPrepare();
      if ($("#cta-single-viatge")[0]) calcCTA();
    }, 1000);
  });
})();

// ······································································
// WINDOW LISTENERS - SCROLL
// ······································································

$(window).on("scroll", function () {
  updateSuperpositions();

  if ($("#cta-single-viatge")[0]) updateCTA();
});

// ······································································
// WINDOW LISTENERS - RESIZE
// ······································································

$(window).on("resize", function () {
  calcScreen();

  if ($("#cta-single-viatge")[0]) calcCTA();
});

// ······································································
// BODY LISTENERS - CLICK
// ······································································

$("body").on("click", (e) => {
  prepareMenuDisplays(e.target);
});
