document.addEventListener("DOMContentLoaded", function () {

    var lazyloadImages = document.querySelectorAll(".lazy");



    // One liner function:
    const addCSS = css => document.head.appendChild(document.createElement("style")).innerHTML = css;

    // Usage: 
    addCSS(`.lazy{ opacity: 0; }`);

    if ("IntersectionObserver" in window) {
        var imageObserver = new IntersectionObserver(function (
            entries,
            observer
        ) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var image = entry.target;
                    showImage(image);
                    imageObserver.unobserve(image);
                }
            });
        });

        lazyloadImages.forEach(function (image) {
            imageObserver.observe(image);
        });
    } else {
        var lazyloadThrottleTimeout;

        function lazyload() {
            if (lazyloadThrottleTimeout) {
                clearTimeout(lazyloadThrottleTimeout);
            }

            lazyloadThrottleTimeout = setTimeout(function () {
                var scrollTop = window.pageYOffset;
                lazyloadImages.forEach(function (img) {
                    if (img.offsetTop < window.innerHeight + scrollTop) {
                        showImage(img);
                    }
                });
                if (lazyloadImages.length == 0) {
                    document.removeEventListener("scroll", lazyload);
                    window.removeEventListener("resize", lazyload);
                    window.removeEventListener("orientationChange", lazyload);
                }
            }, 20);
        }

        document.addEventListener("scroll", lazyload);
        window.addEventListener("resize", lazyload);
        window.addEventListener("orientationChange", lazyload);
    }

    function showImage(img) {
        // console.log('lazy', img);
        img.style.transition = 'all 0.5s ease';
        img.style.opacity = 0;
        img.src = img.dataset.src;
        img.classList.remove("lazy");
        img.style.opacity = 1;
    }

});
